export default defineI18nConfig(() => ({
  locales: ["en", "fr", "it"],
  messages: {
    fr: {
      intro: "AGENCE DE COMMUNICATION 360, DIGITAL NATIVE, ALLIANT CREATIVITE ET TECHNOLOGIE",
      firstParagraph: "Nous sommes une équipe de communicants, de créatifs, de développeurs et de réalisateurs, tous experts dans leurs domaines. Passionnés, nous sommes constamment à la recherche de tendances créatives et d’innovations technologiques.",
      secondParagraph: "Nous croyons à la synergie entre le savoir-faire, l’importance de l’innovation et le pouvoir des émotions pour créer des projets uniques.",
      thirdParagraph: "Avec nos clients, ce sont des histoires sur le long terme que nous écrivons.",
      legalNotice: "Mentions légales",
      introduction: "Cette page fournit des informations essentielles concernant la propriété, le développement, l'hébergement, la propriété intellectuelle et la politique des cookies de notre site web. Elle vise à assurer la transparence et à informer nos utilisateurs sur les aspects clés du fonctionnement et de la gestion de notre site. Nous vous invitons à examiner attentivement ces informations pour comprendre nos pratiques et vos droits en tant qu'utilisateur.",
      owner: "Propriétaire du site",
      ownerName: "LMLC COMMUNICATION",
      ownerLegalForm: "Forme juridique : SAS (Société par Actions Simplifiée)",
      ownerCapital: "Capital social : €5000",
      ownerLocation: "Adresse : 7/11 rue de l'yvette, 75016 PARIS",
      ownerSiret: "Numéro Siret : 911 690 295 00017 (siège social)",
      ownerRcs: "Immatriculation : RCS PARIS",
      ownerCodeNafApe: "Code NAF / APE : 73.11Z (Activités d'agences de publicité)",
      ownerDirectorPublication: "Directeur de la publication : Léo Matthiau, Président de LMLC COMMUNICATION",
      dev: "Développement",
      devArthur: "Arthur TRUSGNACH",
      devArthurContact: "Contact : ",
      devRaphael: "Raphaël THIBAUT",
      devRaphaelContact: "Contact : ",
      hosting: "Hébergement",
      hostingName: "Vercel",
      hostingNote: "Remarque : Vercel est une plateforme d'hébergement réputée pour sa performance et sa fiabilité. Elle offre des solutions d'hébergement pour les applications web modernes, avec une infrastructure sécurisée et une mise à l'échelle automatique. Pour plus d'informations sur les politiques de sécurité, les conditions d'utilisation et la gestion des données chez Vercel, veuillez consulter leur site web officiel ou leur documentation de support.",
      intellectualProperty: "Propriété intellectuelle",
      intellectualPropertyContent: "Tous les contenus présents sur le site lmlccommunication.fr, incluant, sans s'y limiter, les graphiques, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société LMLC COMMUNICATION à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs. Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de LMLC COMMUNICATION. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.",
      cookies: "Politique de cookies",
      cookiesContent: "Cette Politique de Cookies explique comment www.lmlccommunication.fr utilise les cookies et technologies similaires pour vous reconnaître lorsque vous visitez notre site web. Elle explique ce que ces technologies sont et pourquoi nous les utilisons, ainsi que vos droits de contrôler notre utilisation de celles-ci.",
      cookiesWhat: "Qu'est-ce qu'un cookie ?",
      cookiesWhatContent: "Un cookie est un petit fichier texte qui est stocké sur votre ordinateur ou appareil mobile. Les cookies sont largement utilisés pour faire fonctionner les sites web, ou les faire fonctionner plus efficacement, ainsi que pour fournir des informations aux propriétaires du site.",
      cookiesHow: "Cookies utilisés par lmlccommunication.fr",
      cookiesHowContentOne: "Les cookies que nous utilisons sur notre site web sont de nature technique et nécessaires au fonctionnement du site, conformément à l'Article 5(3) de la Directive 2002/58/EC. Nous utilisons spécifiquement des cookies pour :",
      cookiesHowContentTwo: "Préférence de Langue : Ce cookie est essentiel pour mémoriser votre sélection de langue sur notre site web, garantissant que vous pouvez voir notre contenu dans la langue de votre choix.",
      cookiesHowContentThree: "Identification de Première Visite : Ce cookie est crucial pour déterminer si c'est votre première visite sur notre site. Si c'est le cas, une animation d'introduction est jouée.",
      consent: "Votre Consentement et Contrôle",
      consentContent: "En utilisant notre site web, vous reconnaissez l'utilisation de ces cookies nécessaires. Pour des informations détaillées sur les cookies que nous utilisons, vous pouvez consulter notre section de Gestion des Cookies, accessible via un lien dans le pied de page de chaque page.",
      strategies: "stratégie",
      strategiesOne: "stratégie de communication",
      strategiesTwo: "stratégie marketing",
      strategiesThree: "stratégie de marque",
      brandingOne: "direction artistique",
      brandingTwo: "identité visuelle",
      brandingThree: "plateforme de marque",
      brandingFour: "logo & charte graphique",
      brandingFive: "storytelling",
      digitalOne: "social media",
      digitalTwo: "community management",
      digitalThree: "développement web & design",
      digitalFour: "UX/UI",
      digitalFive: "apps",
      brandContentOne: "production audiovisuelle",
      brandContentTwo: "shooting photo",
      brandContentThree: "packshot",
      brandContentFour: "motion design & 3D",
      ceoAndFounder: "CEO & fondateur",
      headNewBusiness: "head of New Business",
      expertMarketing: "experte marketing",
      projectManager: "cheffe de projet",
      filmDirector: "réalisateur",
      webDeveloper: "développeur web",
      artisticDirector: "directrice artistique",
      brandPerformanceExpert: "expert en brand performance ",
      TitleMonin: "MONIN",
      DescriptionMonin: ["Leader mondial des sirops et première référence du", "monde de la mixologie, notre agence accompagne", "l’entreprise familiale dans leurs projets de " , "brandcontent et de social media."],
      DescriptionMoninMobile: "Leader mondial des sirops et première référence du monde de la mixologie, notre agence accompagne l’entreprise familiale dans leurs projets de brandcontent et de social media.",
      WorkMonin:["social media", "community management", "production audiovisuelle", "shooting photo"],

      TitleBourges: ["CAPITALE", "EUROPEENNE", "DE LA CULTURE"],
      DescriptionBourges: ["La ville de Bourges, sacrée capitale européenne de", "la culture 2028, a été accompagnée par notre", " agence dans le cadre de sa candidature, afin de la" , "conduire jusqu'à la victoire."],
      DescriptionBourgesMobile: "La ville de Bourges, sacrée capitale européenne de la culture 2028, a été accompagnée par notre agence dans le cadre de sa candidature, afin de la conduire jusqu'à la victoire.",
      WorkBourges:["consulting", "DA & graphisme", "charte graphique", "édition & mise en page", "production audiovisuelle", "développement web",],
    },
    en: {
      intro: "360 COMMUNICATION AGENCY, DIGITAL NATIVE, COMBINING CREATIVITY AND TECHNOLOGY",
      firstParagraph: "we are a team of communicators, creatives, developers, and directors, all experts in their fields. Passionate, we are constantly on the search of creative trends and technological innovations.",
      secondParagraph: "we believe in the synergy of know-how, the importance of the innovation and the power of emotions to create unique projects.",
      thirdParagraph: "with our clients we write long-term stories.",
      legalNotice: "Legal Notice",
      introduction: "This page provides essential information regarding the ownership, development, hosting, intellectual property, and cookie policy of our website. It is designed to ensure transparency and inform our users about key aspects of our site's operation and management. Please review this information carefully to understand our practices and your rights as a user.",
      owner: "Website Owner",
      ownerName: "LMLC COMMUNICATION",
      ownerLegalForm: "Legal Form: SAS (Société par Actions Simplifiée)",
      ownerCapital: "Capital: €5000",
      ownerLocation: "Address: 7/11 rue de l'yvette, 75016 PARIS",
      ownerSiret: "Siret Number: 911 690 295 00017 (head office)",
      ownerRcs: "Registration: RCS PARIS",
      ownerCodeNafApe: "NAF / APE Code: 73.11Z (Advertising Agency Activities)",
      ownerDirectorPublication: "Director of Publication: Léo Matthiau, President of LMLC COMMUNICATION",
      dev: "Development",
      devArthur: "Arthur TRUSGNACH",
      devArthurContact: "Contact: ",
      devRaphael: "Raphael THIBAUT",
      devRaphaelContact: "Contact: ",
      hosting: "Hosting",
      hostingName: "Vercel",
      hostingNote: "Note: Vercel is a hosting platform renowned for its performance and reliability. It offers hosting solutions for modern web applications, with secure infrastructure and automatic scaling. For more information on security policies, terms of use, and data management at Vercel, please consult their official website or support documentation.",
      intellectualProperty: "Intellectual Property",
      intellectualPropertyContent: "All contents present on the site lmlccommunication.fr, including, but not limited to, graphics, images, texts, videos, animations, sounds, logos, gifs, and icons, as well as their formatting are the exclusive property of LMLC COMMUNICATION except for brands, logos, or content belonging to other partner companies or authors. Any reproduction, distribution, modification, adaptation, retransmission, or publication, even partial, of these various elements is strictly prohibited without the express written consent of LMLC COMMUNICATION. This representation or reproduction, by any means whatsoever, constitutes an infringement sanctioned by articles L.335-2 and following of the Intellectual Property Code.",
      cookies: "Cookie Policy",
      cookiesContent: "This Cookie Policy explains how www.lmlccommunication.fr uses cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.",
      cookiesWhat: "What is a cookie?",
      cookiesWhatContent: "A cookie is a small text file that is stored on your computer or mobile device. Cookies are widely used to make websites work, or to work more efficiently, as well as to provide information to the site owners.",
      cookiesHow: "Cookies used by lmlccommunication.fr",
      cookiesHowContentOne: "The cookies we use on our website are technical in nature and necessary for the functioning of the site, in accordance with Article 5(3) of Directive 2002/58/EC. We specifically use cookies for:",
      cookiesHowContentTwo: "Language Preference: This cookie is essential for remembering your language selection on our website, ensuring that you can view our content in the language of your choice.",
      cookiesHowContentThree: "First Visit Identification: This cookie is crucial for determining if it is your first visit to our site. If so, an introductory animation is played.",
      consent: "Your Consent and Control",
      consentContent: "By using our website, you acknowledge the use of these necessary cookies. For detailed information on the cookies we use, you can consult our Cookie Management section, accessible via a link in the footer of each page.",
      strategies: "strategy",
      strategiesOne: "strategy and communication plan ",
      strategiesTwo: "marketing strategy",
      strategiesThree: "brand strategy",
      brandingOne: "artistic direction",
      brandingTwo: "visual identity",
      brandingThree: "brand platform",
      brandingFour: "logo & graphic charter",
      brandingFive: "storytelling",
      digitalOne: "social media",
      digitalTwo: "community management",
      digitalThree: "web development & design",
      digitalFour: "UX/UI",
      digitalFive: "apps",
      brandContentOne: "audiovisual production",
      brandContentTwo: "shooting photo",
      brandContentThree: "packshot",
      brandContentFour: "motion design & 3D",
      ceoAndFounder: "CEO & founder",
      headNewBusiness: "head of New Business",
      expertMarketing: "marketing expert",
      projectManager: "project manager",
      filmDirector: "film director",
      webDeveloper: "web developer",
      artisticDirector: "artistic director",
      brandPerformanceExpert: "brand performance expert",
      TitleMonin: "MONIN",
      DescriptionMonin: ["World leader in syrups and first reference in the ", "world of mixology, our agency accompanies the ", "family business in their brandcontent and social " , "media projects."],
      DescriptionMoninMobile: "World leader in syrups and first reference in the world of mixology, our agency accompanies the family business in their brandcontent and social media projects.",
      WorkMonin:["social media", "community management", "production audiovisuelle", "shooting photo"],

      TitleBourges: ["EUROPEAN", "CAPITAL", "OF CULTURE"],
      DescriptionBourges: ["The city of Bourges, crowned European Capital of ", "Culture 2028, was supported by our agency in its", "bid to win the title." , ""],
      DescriptionBourgesMobile: "The city of Bourges, crowned European Capital of Culture 2028, was supported by our agency in its bid to win the title.",
      WorkBourges:["consulting", "DA & graphisme", "charte graphique", "édition & mise en page", "production audiovisuelle", "développement web",],    
    },
    it: {
      intro : "AGENZIA DI COMUNICAZIONE 360,  DIGITAL NATIVE, CHE UNISCE CREATIVITÀ E TECNOLOGIA",
      firstParagraph: "siamo un team di comunicatori, designer, sviluppatori e produttori, tutti esperti nei loro settori. Siamo appassionati di ciò che facciamo e siamo costantemente alla ricerca di tendenze creative e innovazioni tecnologiche.",
      secondParagraph: "crediamo nella sinergia tra il know-how, l'importanza dell'innovazione e il potere dell'emozione per creare progetti unici.",
      thirdParagraph: "con i nostri clienti, scriviamo storie a lungo termine.",
      
      legalNotice: "Avviso Legale",
      introduction: "Questa pagina fornisce informazioni essenziali riguardanti la proprietà, lo sviluppo, l'hosting, la proprietà intellettuale e la politica dei cookie del nostro sito web. È progettata per garantire trasparenza e informare i nostri utenti sugli aspetti chiave del funzionamento e della gestione del nostro sito. Si prega di rivedere attentamente queste informazioni per comprendere le nostre pratiche e i vostri diritti come utente.",
      owner: "Proprietario del Sito",
      ownerName: "LMLC COMMUNICATION",
      ownerLegalForm: "Forma Legale: SAS (Société par Actions Simplifiée)",
      ownerCapital: "Capitale: €5000",
      ownerLocation: "Indirizzo: 7/11 rue de l'yvette, 75016 PARIS",
      ownerSiret: "Numero Siret: 911 690 295 00017 (sede principale)",
      ownerRcs: "Registrazione: RCS PARIS",
      ownerCodeNafApe: "Codice NAF / APE: 73.11Z (Attività di agenzie pubblicitarie)",
      ownerDirectorPublication: "Direttore della Pubblicazione: Léo Matthiau, Presidente di LMLC COMMUNICATION",
      dev: "Sviluppo",
      devArthur: "Arthur TRUSGNACH",
      devArthurContact: "Contatto: ",
      devRaphael: "Raphael THIBAUT",
      devRaphaelContact: "Contatto: ",
      hosting: "Hosting",
      hostingName: "Vercel",
      hostingNote: "Nota: Vercel è una piattaforma di hosting rinomata per la sua performance e affidabilità. Offre soluzioni di hosting per applicazioni web moderne, con infrastruttura sicura e scalabilità automatica. Per maggiori informazioni sulle politiche di sicurezza, i termini di utilizzo e la gestione dei dati presso Vercel, si prega di consultare il loro sito web ufficiale o la loro documentazione di supporto.",
      intellectualProperty: "Proprietà Intellettuale",
      intellectualPropertyContent: "Tutti i contenuti presenti sul sito lmlccommunication.fr, inclusi, ma non limitati a, grafiche, immagini, testi, video, animazioni, suoni, loghi, gif e icone, nonché la loro formattazione, sono di proprietà esclusiva della società LMLC COMMUNICATION, ad eccezione dei marchi, loghi o contenuti appartenenti ad altre società partner o autori. Qualsiasi riproduzione, distribuzione, modifica, adattamento, ritrasmissione o pubblicazione, anche parziale, di questi elementi è severamente vietata senza il consenso scritto espressamente di LMLC COMMUNICATION. Questa rappresentazione o riproduzione, con qualsiasi mezzo, costituisce una violazione sanzionata dagli articoli L.335-2 e seguenti del Codice della Proprietà Intellettuale.",
      cookies: "Politica dei Cookie",
      cookiesContent: "Questa Politica sui Cookie spiega come www.lmlccommunication.fr utilizza i cookie e tecnologie simili per riconoscerti quando visiti il nostro sito web. Spiega cosa sono queste tecnologie e perché le utilizziamo, così come i tuoi diritti nel controllare il nostro uso di esse.",
      cookiesWhat: "Cos'è un cookie?",
      cookiesWhatContent: "Un cookie è un piccolo file di testo che viene memorizzato sul tuo computer o dispositivo mobile. I cookie sono ampiamente utilizzati per fare funzionare i siti web, o per farli funzionare più efficacemente, oltre che per fornire informazioni ai proprietari del sito.",
      cookiesHow: "Cookie utilizzati da lmlccommunication.fr",
      cookiesHowContentOne: "I cookie che utilizziamo sul nostro sito web sono di natura tecnica e necessari per il funzionamento del sito, conformemente all'Articolo 5(3) della Direttiva 2002/58/EC. Utilizziamo specificatamente i cookie per:",
      cookiesHowContentTwo: "Preferenza della Lingua: Questo cookie è essenziale per ricordare la tua selezione della lingua sul nostro sito web, garantendo che tu possa vedere i nostri contenuti nella lingua di tua scelta.",
      cookiesHowContentThree: "Identificazione della Prima Visita: Questo cookie è fondamentale per determinare se è la tua prima visita sul nostro sito. In tal caso, viene riprodotta un'animazione introduttiva.",
      consent: "Il Tuo Consenso e Controllo",
      consentContent: "Utilizzando il nostro sito web, riconosci l'uso di questi cookie necessari. Per informazioni dettagliate sui cookie che utilizziamo, puoi consultare la nostra sezione di Gestione dei Cookie, accessibile tramite un link nel footer di ogni pagina.",
      strategies: "strategy",
      strategiesOne: "strategy and communication plan ",
      strategiesTwo: "marketing strategy",
      strategiesThree: "brand strategy",
      brandingOne: "artistic direction",
      brandingTwo: "visual identity",
      brandingThree: "brand platform",
      brandingFour: "logo & graphic charter",
      brandingFive: "storytelling",
      digitalOne: "social media",
      digitalTwo: "community management",
      digitalThree: "web development & design",
      digitalFour: "UX/UI",
      digitalFive: "apps",
      brandContentOne: "audiovisual production",
      brandContentTwo: "shooting photo",
      brandContentThree: "packshot",
      brandContentFour: "motion design & 3D",
      ceoAndFounder: "CEO & founder",
      headNewBusiness: "head of New Business",
      expertMarketing: "marketing expert",
      projectManager: "project manager",
      filmDirector: "film director",
      webDeveloper: "web developer",
      artisticDirector: "artistic director",
      brandPerformanceExpert: "brand performance expert",
      TitleMonin: "MONIN",
      DescriptionMonin: ["Leader mondiale nel settore degli sciroppi e punto ", "di riferimento nel mondo della mixology, la nostra ", "agenzia sta aiutando l'azienda familiare con i suoi" , "progetti di brandcontent e social media."],
      DescriptionMoninMobile: "Leader mondiale nel settore degli sciroppi e punto di riferimento nel mondo della mixology, la nostra agenzia sta aiutando l'azienda familiare con i suoi progetti di brandcontent e social media.",
      WorkMonin:["social media", "community management", "production audiovisuelle", "shooting photo"],

      TitleBourges: ["CAPITALE", "EUROPEA", "DELLA CULTURA"],
      DescriptionBourges: ["La città di Bourges, incoronata Capitale europea", "della cultura 2028, è stata supportata dalla nostra", "agenzia nella sua candidatura al titolo." , ""],
      DescriptionBourgesMobile: "La città di Bourges, incoronata Capitale europea della cultura 2028, è stata supportata dalla nostra agenzia nella sua candidatura al titolo.",
      WorkBourges:["consulting", "DA & graphisme", "charte graphique", "édition & mise en page", "production audiovisuelle", "développement web",],
    },
  },
}));
